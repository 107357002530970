<div class="background-container">
  <div class="detail-container">
    <div class="detail-menu">
      <div class="text-center menu-icon">
        <app-technology-icon
          *ngIf="currentTech.displayName"
          [technologyName]="currentTech.displayName"
          [technologyIconUrl]="currentTech.icon"
        ></app-technology-icon>
        {{ currentTech.displayName }}
      </div>
      <mat-nav-list class="menu-list">
        <a mat-list-item>全体</a>
        <a
          mat-list-item
          *ngFor="let tag of defaultTags"
          (click)="clickGenre(tag.keyName)"
          >{{ tag.displayName }}</a
        >
      </mat-nav-list>
      <mat-nav-list class="menu-list mt-2">
        <a mat-list-item (click)="openNoteAddDialog()">記事追加</a>
      </mat-nav-list>
    </div>
    <div class="detail-notes">
      <div class="articles" *ngFor="let block of blockList">
        <div class="articles-title">
          <label>{{ block.displayName }}</label>
        </div>
        <div class="article-container" *ngFor="let note of block.notes">
          <div class="article">
            <div class="article-info">
              <div class="annotation mat-small">
                <label class="annotation-label"
                  >投稿：{{ note.createByName }}</label
                >
                <label class="annotation-label"
                  >作成：{{ note.createAt | date: 'yyyy/MM/dd' }}</label
                >
              </div>
            </div>
            <a
              [href]="note.url"
              class="title-link"
              target="_blank"
              rel="noopener noreferrer"
              >{{ note.title }}
            </a>
            <app-star-icon
              [parameter]="note.rating"
              [isSmall]="true"
            ></app-star-icon>
          </div>
          <mat-divider></mat-divider>
        </div>
        <button
          mat-raised-button
          class="btn show-more-btn"
          (click)="clickReadMore(block.keyName)"
        >
          もっと見る
        </button>
      </div>
    </div>
  </div>
</div>
