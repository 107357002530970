import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Note } from 'src/app/page/technology/note/new/technology-note-new.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NoteService {
  private apiRoute = environment.apiUrl;

  constructor(private http: HttpClient) {}

  /**
   * 記事の取得の共通化
   * @param query 記事取得クエリ
   * @returns 記事
   */
  public getNotes<T>(query: any): Observable<T> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: query
    };
    return this.http.get<T>(`${environment.apiUrl}/notes`, options);
  }

  /**
   * 記事追加
   */
  public postNote(userId: string, note: Note): Observable<any> {
    return this.http.post(`${environment.apiUrl}/users/${userId}/notes`, note);
  }

  /**
   * 記事検索
   * @returns 記事情報
   */
  public searchNoteInfo<T>(noteUrl: string): Observable<T> {
    return this.http.get<T>(`${this.apiRoute}/notes/search/?url=${noteUrl}`);
  }
}
