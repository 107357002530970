<div class="background-container">
  <div class="detail-container">
    <div class="detail-menu">
      <div class="text-center menu-icon">
        <app-technology-icon
          *ngIf="currentTech.displayName"
          [technologyName]="currentTech.displayName"
          [technologyIconUrl]="currentTech.icon"
        ></app-technology-icon>
        {{ currentTech.displayName }}
      </div>
      <mat-nav-list class="menu-list">
        <a mat-list-item (click)="clickedTechnologyDetail()">全体</a>
        <a
          mat-list-item
          *ngFor="let tag of defaultTags"
          (click)="clickGenre(tag.keyName)"
          >{{ tag.displayName }}</a
        >
      </mat-nav-list>
    </div>
    <div class="detail-notes">
      <div class="articles">
        <div class="articles-title">
          <label>{{ currentTag?.displayName }}</label>
        </div>
        <div class="article-container" *ngFor="let note of notes">
          <div class="article">
            <div class="article-info">
              <div class="annotation mat-small">
                <label class="annotation-label"
                  >投稿：{{ note.createByName }}</label
                >
                <label class="annotation-label"
                  >作成：{{ note.createAt | date: 'yyyy/MM/dd' }}</label
                >
              </div>
            </div>
            <a [href]="note.url" class="title-link">{{ note.title }}</a>
            <app-star-icon
              [parameter]="note.rating"
              [isSmall]="true"
            ></app-star-icon>
          </div>
          <mat-divider></mat-divider>
        </div>
        <label class="disclaimer" *ngIf="this.isLast!"
          >これ以上記事がありません</label
        >
        <button
          mat-raised-button
          [style.visibility]="this.isLast ? 'hidden' : 'visible'"
          class="btn show-more-btn"
          (click)="clickedReadMore()"
        >
          もっと見る
        </button>
      </div>
    </div>
    <div class="detail-relations"></div>
  </div>
</div>
