import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-technology-icon',
  templateUrl: './technology-icon.component.html',
  styleUrls: ['./technology-icon.component.scss']
})
export class TechnologyIconComponent implements OnChanges {
  constructor() {}

  @Input() technologyName = '';
  @Input() technologyIconUrl = '';
  public technologyInitial = '';

  @Input() isSmall = false;

  ngOnChanges(changes: SimpleChanges): void {
    const techNameChange = changes['technologyName'];
    if (techNameChange && techNameChange.currentValue) {
      this.technologyInitial =
        techNameChange.previousValue != techNameChange.currentValue
          ? techNameChange.currentValue.slice(0, 1)
          : techNameChange.previousValue;
    }
  }
}
