<div class="tech-add-page">
  <div class="main-div">
    <div class="icon-container">
      <div *ngIf="iconPath">
        <img [src]="iconPath" class="tech-icon" alt="アイコン写真" />
      </div>
    </div>
    <input
      #fileInputEl
      class="hide"
      type="file"
      accept="image/*"
      accept=".svg, .jpeg, .jpg, .png, .gif, .dib, .webp, .svgz, .tif, .ico, .xbm, .bmp, .jtif, .pjpeg, .pjp, .tiff"
      (change)="saveImage($event)"
    />
    <button
      mat-raised-button
      color="primary"
      class="btn-style"
      (click)="onClickFileInputButton(fileInputEl)"
    >
      アイコン
    </button>
    <form class="input-form">
      <mat-form-field class="full-width">
        <input
          matInput
          [(ngModel)]="data.name"
          name="name"
          placeholder="名称"
          value=""
          (input)="changeInputTechnologyName()"
        />
      </mat-form-field>
      <mat-form-field class="full-width">
        <input
          matInput
          [(ngModel)]="data.officialUrl"
          name="url"
          placeholder="公式ドキュメントURL"
          value=""
        />
      </mat-form-field>
    </form>
    <div class="text-center">
      類似する登録済みの技術
      <div class="mt-3 ms-5">
        <mat-chip-set>
          <mat-chip *ngFor="let tech of registrationTechList">
            {{ tech.displayName }}
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <div class="btn-container full-width">
      <button
        mat-raised-button
        color="accent"
        class="btn-style"
        (click)="postTechnology()"
      >
        登録
      </button>
      <button
        mat-raised-button
        color="basic"
        class="btn-style"
        [mat-dialog-close]
      >
        キャンセル
      </button>
    </div>
  </div>
</div>
<!-- 画像サイズ読み込み用イメージ -->
<img
  #tempImageEl
  [src]="tempImageSrc"
  class="hide"
  (load)="loadedTempImage(tempImageEl)"
/>
