import { Component, OnInit } from '@angular/core';
import { AppSnackbarService } from 'src/app/service/app-snackbar/app-snackbar.service';
import { SandboxService } from '../../service/sandbox/sandbox.service';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss']
})
export class SandboxComponent implements OnInit {
  constructor(
    private sandboxService: SandboxService,
    private snackbar: AppSnackbarService
  ) {}

  ngOnInit(): void {}

  public clickedTest() {
    console.log(Date.now(), 'clickedTest');
    this.sandboxService.testGet().subscribe(data => {
      console.log(data);
    });
  }

  public showSnackbar() {
    this.snackbar.error('エラー表示時間の確認');
  }
}
