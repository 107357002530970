import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { HomeTechnology } from './home.model';
import { TechnologyNewComponent } from '../technology/new/technology-new.component';
import { TechnologyService } from 'src/app/service/technology/technology.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  displayedColumns: string[] = ['icon', 'name', 'officialUrl'];
  dataSource: any;
  technologies: HomeTechnology[] = [];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private technologyService: TechnologyService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.searchTeachnologies();
  }

  /** 技術一覧を検索します */
  private searchTeachnologies() {
    this.technologyService
      .getTechnologies()
      .subscribe((d: HomeTechnology[]) => {
        this.technologies = d;
        this.dataSource = new MatTableDataSource(this.technologies);
        this.dataSource.paginator = this.paginator;

        // 技術名のみでのフィルタを設定
        this.dataSource.filterPredicate = (
          data: HomeTechnology,
          filter: string
        ) => {
          return data.keyName.toLowerCase().includes(filter);
        };
      });
  }

  /** 技術クリックイベント */
  public clickTechnology(code: string, event: Event) {
    if (!(event.target as HTMLAnchorElement).href) {
      this.router.navigateByUrl(`technologies/${code}`);
    }
  }

  /** 新しい技術を追加するクリックイベント */
  public clickedAddTechnology() {
    const ref = TechnologyNewComponent.openDialog(this.dialog);
    ref.afterClosed().subscribe(x => {
      if (x) {
        this.searchTeachnologies();
      }
    });
  }

  /** 表示技術のフィルタ */
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.toLowerCase();
  }
}
