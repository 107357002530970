<div class="container">
  <div class="mt-3 mb-5">
    下に表示されているボタンをブックマークバーにドラッグ&ドロップします
  </div>
  <a
    class="link-button"
    [href]="getSantizeUrl(bookmarkletScript)"
    target="_blank"
    >記事共有</a
  >
  <div class="mt-5 mb-3">
    <p>共有したい記事を開き、ブックマークバーに追加された</p>
    <p>「記事共有」をクリックすることで、記事共有画面に遷移します</p>
  </div>
</div>
