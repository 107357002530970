import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSnackbarService } from 'src/app/service/app-snackbar/app-snackbar.service';
import {
  Tag,
  TechnologyNoteQuery,
  DocumentPagingModel
} from '../technology.models';
import { MatDialog } from '@angular/material/dialog';
import { TechnologyNoteNewComponent } from '../note/new/technology-note-new.component';
import { TechnologyRelationNewComponent } from '../relation/new/technology-relation-new.component';
import { RouterService } from 'src/app/service/router/router.service';
import {
  TechnologyViewModel,
  TechnologyRelationDialogResult,
  TechnologyDetailInit
} from '../technology.models';
import { TechnologyService } from 'src/app/service/technology/technology.service';
import { NoteService } from 'src/app/service/note/note.service';
import { DisplayBlock } from '../note/home/technology-note-home.model';
import { UserService } from 'src/app/service/user/user.service';

@Component({
  selector: 'app-technology-home',
  templateUrl: './technology-home.component.html',
  styleUrls: ['./technology-home.component.scss']
})
export class TechnologyHomeComponent implements OnInit {
  /** 選択中の技術 */
  public currentTech = new TechnologyViewModel();
  /** 初期表示する記事 */
  public blockList: DisplayBlock[] = [];

  /** 記事種別 */
  public defaultTags: Tag[] = [];

  /** 技術コード */
  public techId: string;

  constructor(
    private technologyService: TechnologyService,
    private userService: UserService,
    private noteService: NoteService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private snackbar: AppSnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.techId = this.activatedRoute.snapshot.paramMap.get('techId');
    if (!this.techId) {
      // 技術コードが取得できない場合はホーム画面へ
      this.routerService.navigateByUrl(`technologies/home`);
      return;
    }

    // 初期表示情報の取得
    this.technologyService
      .getTechnology<TechnologyDetailInit>(this.techId)
      .subscribe(data => {
        if (!data || !data.technology) {
          this.snackbar.error('対象の技術が存在しません');
          this.currentTech = new TechnologyViewModel();
          return;
        }

        this.currentTech = data.technology;

        // 初期表示タグの取得
        this.userService.getDefaultTag().subscribe(tags => {
          this.defaultTags = tags;
          this.defaultTags.forEach(tag => {
            this.getNotesInit(tag);
          });
        });
      });
  }

  /** ジャンルタグ選択時 */
  public clickGenre(tagName: string): void {
    this.clickReadMore(tagName);
  }

  /** もっと見る押下時 */
  public clickReadMore(tagName: string): void {
    this.routerService.navigateByUrl(
      `technologies/${this.techId}/note/${tagName}`
    );
  }

  /**
   * 記事の取得
   * @param tag 記事種別
   */
  public getNotesInit(tag: Tag): any {
    let query = new TechnologyNoteQuery();
    // ページング用クエリ情報格納
    query.keyName = this.currentTech.keyName;
    query.tag = tag.keyName;
    query.take = 3;
    query.docId = '';

    this.noteService.getNotes<DocumentPagingModel>(query).subscribe(data => {
      let defaultNote = new DisplayBlock();
      // 画面表示用記事情報格納
      defaultNote.displayName = tag.displayName;
      defaultNote.keyName = tag.keyName;
      defaultNote.notes = data.notes;
      this.blockList.push(defaultNote);
    });
  }

  /**
   * 記事追加のダイアログ表示
   * @param path url
   */
  public openNoteAddDialog(): void {
    this.dialog.open(TechnologyNoteNewComponent);
  }
}
