import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AppSnackbarService {
  constructor(private snackbar: MatSnackBar) {}

  public error(msg: string) {
    this.snackbar.open(msg, 'error', {
      duration: msg.length * 200,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  public success(msg: string) {
    this.snackbar.open(msg, 'success', {
      duration: msg.length * 200,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }
}
