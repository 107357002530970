import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-star-icon',
  templateUrl: './star-icon.component.html',
  styleUrls: ['./star-icon.component.scss']
})
export class StarIconComponent implements OnInit {
  @Input() parameter = 0;
  @Input() isSmall = false;

  public points: number[] = [];

  constructor() {}

  ngOnInit(): void {
    for (let ii = 0; ii < Math.floor(this.parameter); ii++) {
      this.points.push(1);
    }
    if (this.parameter % 1 !== 0) {
      this.points.push(0.5);
    }
  }
}
