import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './page/home/home.component';
import { LoginFailedComponent } from './page/login-failed/login-failed.component';
import { SandboxComponent } from './page/sandbox/sandbox.component';
import { TechnologyHomeComponent } from './page/technology/home/technology-home.component';
import { TechnologyNoteHomeComponent } from './page/technology/note/home/technology-note-home.component';
import { TechnologyNoteNewComponent } from './page/technology/note/new/technology-note-new.component';
import { UserHomeComponent } from './page/user/home/user-home.component';

const routes: Routes = [
  { path: '', component: HomeComponent },

  { path: 'technologies/home', component: TechnologyHomeComponent, canActivate: [MsalGuard]  },
  { path: 'technologies/:techId', component: TechnologyHomeComponent, canActivate: [MsalGuard]  },
  { path: 'technologies/:techId/note/:tagName', component: TechnologyNoteHomeComponent, canActivate: [MsalGuard]  },
  { path: 'technologies/:techId/relation', component: TechnologyHomeComponent, canActivate: [MsalGuard]  },
  { path: 'technology/noteAdd/:title/:url', redirectTo: 'notes/noteNew/:title/:url'}, // ブックマークから呼ばれるから残す
  { path: 'notes/noteNew/:title/:url', component: TechnologyNoteNewComponent, canActivate: [MsalGuard]},
  { path: 'users/home', component: UserHomeComponent, canActivate: [MsalGuard]  },
  { path: 'login/failed', component: LoginFailedComponent },
  { path: 'sandbox', component: SandboxComponent },
  { path: '**', redirectTo: '' }
];

const isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled' // Remove this line to use Angular Universal
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
