import { Note } from '../../technology.models';

/** 初期表示記事情報 */
export class DisplayBlock {
  /** タグ名 */
  keyName: string;
  /** 表示名 */
  displayName: string;
  /** 記事 */
  notes: Note[];
}
