<div class="star-container">
  <ng-container *ngIf="this.isSmall">
    <ng-container *ngFor="let point of points">
      <ng-container *ngIf="point === 1">
        <mat-icon class="small-star-icon">star_rate</mat-icon>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!this.isSmall">
    <ng-container *ngFor="let point of points">
      <ng-container *ngIf="point === 1">
        <mat-icon class="star-icon">star_rate</mat-icon>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
