<ng-container *ngIf="!isSmall">
  <img
    *ngIf="technologyIconUrl"
    [src]="technologyIconUrl"
    class="technology-icon"
  />
  <div *ngIf="!technologyIconUrl" class="instead-icon">
    {{ technologyInitial }}
  </div>
</ng-container>
<ng-container *ngIf="isSmall">
  <img matListIcon *ngIf="technologyIconUrl" [src]="technologyIconUrl" />
  <div *ngIf="!technologyIconUrl" class="instead-small-icon">
    {{ technologyInitial }}
  </div>
</ng-container>
