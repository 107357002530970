import { NgStyle } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-common';
import { of, throwError } from 'rxjs';
import { Observable, of as observableOf } from 'rxjs';
import { catchError, concatMap, retryWhen, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private authService: MsalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // アカウントが無ければログインし直し
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      this.authService.loginRedirect({
        scopes: ['email'],
        redirectUri: window.location.href
      });
      return next.handle(req);
    }

    // ベアラトークンセット関数定義
    const setBearer = (
      data: AuthenticationResult
    ): Observable<HttpEvent<any>> => {
      if (!data || !data.idToken) {
        // トークンが正しく取れなかった様子なので、認証し直し
        this.authService
          .acquireTokenRedirect({
            scopes: ['email'],
            account: account,
            redirectStartPage: window.location.href
          })
          .subscribe();
        return new Observable<HttpEvent<any>>();
      }

      // 問題ないので、ヘッダーにIDトークンを持たせる
      const clone = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + data.idToken)
      });

      // 次の処理へ
      return next
        .handle(clone)
        .pipe(catchError(error => this.handleHttpError(error)));
    };

    // トークン取得
    return this.authService
      .acquireTokenSilent({
        scopes: ['email'],
        account: account
      })
      .pipe(switchMap(data => setBearer(data)));
  }

  private handleHttpError(err: HttpErrorResponse): Observable<any> {
    if (400 <= err.status && err.status < 500) {
      // TODO: クライアント起因のエラーがある場合はここに追加
      // メンテナンス中とか
      return observableOf(err.message);
    } else if (err.status >= 500) {
      return observableOf(err.message);
    }

    return throwError(err);
  }
}
