import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from '../../technology.models';
import { Note } from './technology-note-new.model';
import { AppAuthService } from 'src/app/service/app-auth/app-auth.service';
import { AppSnackbarService } from 'src/app/service/app-snackbar/app-snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TechnologyNewComponent } from '../../new/technology-new.component';
import { TechnologyService } from 'src/app/service/technology/technology.service';
import { NoteService } from 'src/app/service/note/note.service';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { UserService } from 'src/app/service/user/user.service';

export interface Categories {
  name: string;
  code: number;
}

@Component({
  selector: 'app-technology-note-new',
  templateUrl: './technology-note-new.component.html',
  styleUrls: ['./technology-note-new.component.scss']
})
export class TechnologyNoteNewComponent implements OnInit {
  /** 選択された記事種別 */
  public selectedCategory: string;
  /** 選択された技術 */
  public techTags: string[] = [];
  /** 技術一覧(全量) */
  private allTechList: string[] = [];
  /** 技術一覧 */
  public selectableTechList: string[] = [];
  /** オートコンプリート用 */
  public filteredTechList: Observable<string[]>;
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public techCtrl = new FormControl();

  public techList: Tag[] = [];

  /** 記事情報 */
  public note: Note = new Note();
  /** 自分で書いた記事か */
  public writtenFlag: boolean = false;
  /** 記事種別 */
  public defaultTags: Tag[] = [];

  @ViewChild('techInput') techInput: ElementRef<HTMLInputElement>;

  constructor(
    private snackbar: AppSnackbarService,
    private technologyService: TechnologyService,
    private noteService: NoteService,
    private userService: UserService,
    private appAuthService: AppAuthService,
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.filteredTechList = this.techCtrl.valueChanges.pipe(
      startWith(null),
      map((tech: string | null) =>
        tech ? this._filter(tech) : this.selectableTechList.slice()
      )
    );
  }

  ngOnInit(): void {
    this.note.title = this.activatedRoute.snapshot.paramMap.get('title') ?? '';
    this.note.url = this.activatedRoute.snapshot.paramMap.get('url') ?? '';

    this.getTechnologyList();

    this.noteService
      .searchNoteInfo<Note>(encodeURI(this.note.url))
      .subscribe((response: Note) => {
        if (!response || !response.url) {
          return;
        }
        this.note = response;

        // 登録済み記事種別の表示
        Object.entries(this.note.genres).forEach(genre => {
          if (genre[1]) {
            this.selectedCategory = genre[0];
          }
        });

        this.techTags = this.note.tags;

        // 自分で書いた記事の場合、チェックボックスをON
        this.writtenFlag = this.note.writtenBy === this.appAuthService.userId;
      });

    // 初期表示タグの取得
    this.userService.getDefaultTag().subscribe(tags => {
      this.defaultTags = tags;
    });
  }

  /** 技術一覧取得 */
  private getTechnologyList(selectName: string = null) {
    this.technologyService.getTechnologies().subscribe((response: Tag[]) => {
      this.allTechList = response.map(x => x.keyName);
      this.selectableTechList = this.allTechList;
      if (selectName) {
        this.techTags.push(selectName);
      }
    });
  }

  // 記事追加
  public AddNote(): void {
    this.note.tags = this.techTags;
    // 記事種別を登録
    this.note.genres[this.selectedCategory] = true;

    // 自分で書いた記事か
    if (this.writtenFlag) {
      this.note.writtenBy = this.appAuthService.userId;
      this.note.writtenByName = this.appAuthService.userName;
    } else {
      this.note.writtenBy = '';
      this.note.writtenByName = '';
    }

    this.note.createBy = this.appAuthService.userId;
    this.note.createByName = this.appAuthService.userName;
    this.note.updateBy = this.appAuthService.userId;

    this.noteService.postNote(this.appAuthService.userId, this.note).subscribe(
      (response: Observable<any>) => {
        this.snackbar.success('記事を登録しました。');
      },
      (res: HttpErrorResponse) => {
        this.snackbar.error('記事の登録に失敗しました。');
      }
    );
  }

  /** 新しい技術の追加ボタンイベント */
  public clickedAddTechnology() {
    TechnologyNewComponent.openDialog(this.dialog)
      .afterClosed()
      .subscribe(x => {
        if (x) {
          this.getTechnologyList(x.name);
        }
      });
  }

  /** 技術タグ追加 */
  public addTechTag(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      //選択肢にあるかチェック
      const tech = this.selectableTechList.find(
        x => x.toLowerCase() == value.trim().toLowerCase()
      );
      if (tech) {
        // 選択肢にあれば技術タグに追加
        this.techTags.push(tech);
        // 選択肢から削除
        this.selectableTechList = this.selectableTechList.filter(
          x => x != tech
        );
      }
    }

    // 入力状態のリセット
    if (input) {
      input.value = '';
    }
    this.techCtrl.setValue(null);
  }

  /** 技術タグ削除 */
  public removeTechTag(tech: string): void {
    this.techTags = this.techTags.filter(x => x != tech);
    this.selectableTechList.push(tech);
  }

  /** 技術タグ選択 */
  public selectedTechTag(event: MatAutocompleteSelectedEvent): void {
    const tech = event.option.viewValue;
    // 選択中の技術タグに追加
    this.techTags.push(tech);
    // 選択肢から削除
    this.selectableTechList = this.selectableTechList.filter(x => x != tech);

    this.techInput.nativeElement.value = '';
    this.techCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.selectableTechList.filter(
      tech => tech.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
