<div class="background-container">
  <div class="controll-panel">
    <button
      mat-raised-button
      class="controll-button"
      color="primary"
      (click)="clickedAddTechnology()"
    >
      新しく技術を追加する
    </button>
    <div class="search-container">
      <input
        class="search-input mat-elevation-z8"
        (keyup)="applyFilter($event)"
        placeholder="検索"
        #input
      />
      <mat-icon class="icon-in-input">search</mat-icon>
    </div>
    <button mat-raised-button class="controll-button invisible">
      <!-- 幅調整用の空要素-->
    </button>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource">
      <!-- Icon Column -->
      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef>Icon</th>
        <td
          mat-cell
          *matCellDef="let element"
          (click)="clickTechnology(element.id, $event)"
          class="select-cursor icon-column-width"
        >
          <app-technology-icon
            [technologyName]="element.displayName"
            [technologyIconUrl]="element.icon"
          ></app-technology-icon>
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td
          mat-cell
          *matCellDef="let element"
          (click)="clickTechnology(element.id, $event)"
          class="select-cursor"
        >
          {{ element.displayName }}
        </td>
      </ng-container>

      <!-- URL Column -->
      <ng-container matColumnDef="officialUrl">
        <th mat-header-cell *matHeaderCellDef>Url</th>
        <td
          mat-cell
          *matCellDef="let element"
          (click)="clickTechnology(element.id, $event)"
          class="select-cursor"
        >
          <a
            [href]="element.officialUrl"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ element.officialUrl }}
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          "{{ input.value }}" に合致する技術がありません
        </td>
      </tr>
    </table>

    <mat-paginator
      [pageSize]="5"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>
