/** 技術情報 */
export class Tag {
  /** タグID */
  public id: string = '';
  /** タグ名 */
  public keyName: string = '';
  /** 表示タグ名 */
  public displayName: string = '';
}

/** 技術情報Model */
export class TechnologyViewModel extends Tag {
  /** アイコンURL */
  public icon!: string;
  /** 公式ドキュメントURL */
  public officialUrl!: string;
  /** 作成日 */
  public createAt!: Date;
  /** 作成者 */
  public createBy!: string;
  /** 更新日 */
  public updateAt!: Date;
  /** 更新者 */
  public updateBy!: string;
}

/** 初期表示情報 */
export class TechnologyDetailInit {
  /** 選択中の技術 */
  public technology!: TechnologyViewModel;
}

/** 技術追加 */
export class TechnologyNewModel {
  public name: string = '';

  public officialUrl: string = '';

  public createBy: string = '';

  public updateBy: string = '';

  public technologyImage: File | null = null;
}

/** 技術関連登録ダイアログ 戻り値一覧 */
export class TechnologyRelationDialogResult {
  /** 関連技術登録 */
  static readonly REGISTED = 'registed';
}

/** 記事情報 */
export class Note {
  /** 記事コード */
  public code!: number;
  /** 技術コード */
  public techId!: string;
  /** タグ */
  public tags!: string[];
  /** 記事種別コード */
  public noteTypeCode!: number;
  /** 記事タイトル */
  public title!: string;
  /** 記事URL */
  public url!: string;
  /** 評価 */
  public rating!: number;
  /** 著者ID */
  public writtenBy!: string;
  /** 著者名 */
  public writtenByName!: string;
  /** 作成者ID(登録者ID) */
  public createBy!: string;
  /** 作成者名(登録者名） */
  public createByName!: string;
  /** 作成日(登録日) */
  public createAt!: Date;
}

export class TechnologyNoteQuery {
  /** 技術ID */
  public keyName: string;
  /** タグ名 */
  public tag: string;
  /** テイク(ページング用) */
  public take: number;
  /** ドキュメントID */
  public docId: string;
}

export class DocumentPagingModel {
  // 記事一覧
  public notes: Note[];
  // 次の記事ID
  public nextDocId: string;
}
