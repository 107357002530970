import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookmarkletDialogComponent } from 'src/app/shared/header/bookmarklet-dialog/bookmarklet-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public headerItem!: any[];

  constructor(public dialog: MatDialog) {}
  ngOnInit(): void {
    this.headerItem = environment.production
      ? [{ value: '', label: 'ホーム' }]
      : [
          { value: '', label: '技術詳細' },
          { value: '', label: '技術ホーム' },
          { value: 'users/home', label: 'ユーザーホーム' },
          { value: 'sandbox', label: 'お砂場' }
        ];
  }

  openDialog() {
    this.dialog.open(BookmarkletDialogComponent);
  }
}
