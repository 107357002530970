import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Relationship } from 'src/app/page/technology/relation/new/technology-relation-new.model';
import {
  Tag,
  TechnologyNewModel
} from 'src/app/page/technology/technology.models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TechnologyService {
  private apiRoute = environment.apiUrl;

  constructor(private http: HttpClient) {}

  /**
   * 技術一覧取得
   * @returns 技術一覧
   */
  public getTechnologies(): Observable<Tag[]> {
    return this.http.get<Tag[]>(`${this.apiRoute}/technologies`);
  }

  /**
   * 技術情報取得
   * @param techId 技術コード
   * @returns 技術情報
   */
  public getTechnology<T>(techId: string): Observable<T> {
    return this.http.get<T>(`${this.apiRoute}/technologies/${techId}`);
  }

  /**
   * 技術情報追加
   */
  public postTechnology(tech: TechnologyNewModel): Observable<any> {
    return this.http.post(
      environment.apiUrl + '/technologies',
      this.convertFormData(tech)
    );
  }
  private convertFormData(data: any): FormData {
    const formData = new FormData();
    Object.keys(data).map(key => {
      formData.append(key, data[key] as any);
    });
    return formData;
  }

  /**
   * 関連性の登録
   * @param relationship 登録する関連性
   */
  public postRelation<T>(relationship: Relationship): Observable<T> {
    return this.http.post<T>(
      `${this.apiRoute}/technologies/${relationship.techParent}/relationship/${relationship.techChild}`,
      relationship
    );
  }
}
