<div class="menu">
  <ng-container *ngFor="let item of headerItem">
    <a class="menu_item" [routerLink]="item.value">{{ item.label }}</a>
  </ng-container>
  <a class="menu_item" (click)="openDialog()">ブックマークレット登録</a>
  <a
    class="menu_item external_link"
    href="https://basic-learning-blog.azurewebsites.net/?page_id=376"
    target="_blank"
    >リファレンス</a
  >
</div>
