import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
import { AppHttpInterceptor } from './intercepter/app.http.intercepter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';

// コンポーネント
import { LoginFailedComponent } from './page/login-failed/login-failed.component';
import { HomeComponent } from './page/home/home.component';
import { TechnologyIconComponent } from './shared/technology-icon/technology-icon.component';
import { BookmarkletDialogComponent } from './shared/header/bookmarklet-dialog/bookmarklet-dialog.component';
import { StarIconComponent } from './shared/star-icon/star-icon.component';
import { SandboxComponent } from './page/sandbox/sandbox.component';
import { AppComponent } from './app.component';
import { TechnologyHomeComponent } from './page/technology/home/technology-home.component';
import { TechnologyNewComponent } from './page/technology/new/technology-new.component';
import { TechnologyNoteHomeComponent } from './page/technology/note/home/technology-note-home.component';
import { TechnologyNoteNewComponent } from './page/technology/note/new/technology-note-new.component';
import { TechnologyRelationNewComponent } from './page/technology/relation/new/technology-relation-new.component';
import { TechnologyRelationHomeComponent } from './page/technology/relation/home/technology-relation-home.component';
import { UserHomeComponent } from './page/user/home/user-home.component';
import { UserNewComponent } from './page/user/new/user-new.component';
import { HeaderComponent } from './shared/header/header.component';

// AngularMaterial
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSnackBarModule } from '@angular/material/snack-bar';

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(environment.msalConfig);
}

function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login/failed'
  };
}

@NgModule({
  declarations: [
    // Angular
    AppComponent,

    // UserApp
    TechnologyHomeComponent,
    HeaderComponent,
    LoginFailedComponent,
    HomeComponent,
    SandboxComponent,
    StarIconComponent,
    TechnologyIconComponent,
    BookmarkletDialogComponent,
    TechnologyNewComponent,
    TechnologyNoteHomeComponent,
    TechnologyNoteNewComponent,
    TechnologyRelationNewComponent,
    TechnologyRelationHomeComponent,
    UserHomeComponent,
    UserNewComponent
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,

    // Angular Material
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    MatGridListModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatListModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatTableModule,

    // etc
    MsalModule
  ],
  providers: [
    // MSAL
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    // my http intercepter
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
