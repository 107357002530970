import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Tag } from 'src/app/page/technology/technology.models';
import { environment } from 'src/environments/environment';
import { AppAuthService } from '../app-auth/app-auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiRoute = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private appAuthService: AppAuthService
  ) {}

  /**
   * ユーザー情報取得
   * @returns ユーザー情報
   */
  public init(model: any): Observable<Object> {
    return this.http.post(`${this.apiRoute}/users`, model);
  }

  /**
   * デフォルトタグの取得
   * @returns デフォルトタグ
   */
  public getDefaultTag(): Observable<Tag[]> {
    let userId = this.appAuthService.userId;
    return this.http.get<Tag[]>(
      `${this.apiRoute}/users/${userId}/settings/default-tag`
    );
  }
}
