<div class="content-container">
  <div class="page-title mt-3 mb-3">
    <p>記事投稿</p>
  </div>
  <mat-form-field class="input-url mt-3" appearance="fill">
    <mat-label>記事タイトル </mat-label>
    <input matInput placeholder="" [(ngModel)]="note.title" />
  </mat-form-field>
  <mat-form-field class="input-url mt-3" appearance="fill">
    <mat-label>URL</mat-label>
    <input matInput placeholder="https://" [(ngModel)]="note.url" />
  </mat-form-field>
  <div class="check-container">
    <mat-checkbox type="checkbox" class="mb-2" [(ngModel)]="writtenFlag">
      自分が書いた記事
    </mat-checkbox>
  </div>
  <div class="select-container">
    <mat-form-field appearance="fill">
      <mat-label>カテゴリ選択</mat-label>
      <mat-select [(ngModel)]="selectedCategory" name="category">
        <mat-option
          *ngFor="let category of defaultTags"
          [value]="category.keyName"
        >
          {{ category.displayName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="technology-selection-row">
      <mat-form-field class="col-8">
        <mat-label>技術タグ選択</mat-label>
        <mat-chip-grid #chipList>
          <mat-chip-row
            *ngFor="let tech of techTags"
            (removed)="removeTechTag(tech)"
            color="primary"
            highlighted
          >
            {{ tech }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip-row>
          <input
            #techInput
            placeholder="選択してください"
            [formControl]="techCtrl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addTechTag($event)"
          />
        </mat-chip-grid>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="selectedTechTag($event)"
        >
          <mat-option
            *ngFor="let tech of filteredTechList | async"
            [value]="tech"
          >
            {{ tech }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <div>
        <button mat-stroked-button (click)="clickedAddTechnology()">
          新しい技術を追加する
        </button>
      </div>
    </div>
  </div>
  <div class="mb-3">
    <button
      mat-raised-button
      color="accent"
      class="registration-button mt-2"
      (click)="AddNote()"
    >
      登録
    </button>
  </div>
</div>
