import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class AppAuthService {
  /** ログイン中のユーザーIDを取得します */
  public get userId(): string {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      return '';
    }
    return account.username.split('@')[0];
  }

  /** ログイン中のユーザー名を取得します */
  public get userName(): string {
    const account = this.authService.instance.getActiveAccount();
    if (!account) {
      return '';
    }

    if (!account.name) {
      return this.userId;
    }

    return account.name;
  }

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {}
}
