import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Tag } from '../technology.models';
import { AppAuthService } from 'src/app/service/app-auth/app-auth.service';
import { AppSnackbarService } from 'src/app/service/app-snackbar/app-snackbar.service';
import { TechnologyNewModel } from '../technology.models';
import { TechnologyService } from 'src/app/service/technology/technology.service';

type ImagePath = string | ArrayBuffer | null;

@Component({
  selector: 'app-technology-new',
  templateUrl: './technology-new.component.html',
  styleUrls: ['./technology-new.component.scss']
})
export class TechnologyNewComponent implements OnInit {
  /** 画像パス */
  public iconPath: ImagePath = null;
  /** 登録情報 */
  public data = new TechnologyNewModel();
  /** 画像サイズ読み込み用イメージ */
  public tempImageSrc: ImagePath = null;
  /** 技術一覧 */
  public techList: Tag[] = [];
  /** 登録済み類似技術リスト */
  public registrationTechList: Tag[] = [];

  constructor(
    private snackbar: AppSnackbarService,
    private technologyService: TechnologyService,
    private appAuthService: AppAuthService,
    private dialogRef: MatDialogRef<TechnologyNewComponent>
  ) {}

  ngOnInit(): void {
    this.technologyService.getTechnologies().subscribe((response: Tag[]) => {
      this.techList = response;
    });
  }

  /**
   * このコンポーネントをダイアログで開きます
   * @param dialog ダイアログサービス
   */
  public static openDialog(
    dialog: MatDialog
  ): MatDialogRef<TechnologyNewComponent, TechnologyNewModel> {
    return dialog.open(TechnologyNewComponent, {
      width: '35rem',
      height: '35rem'
    });
  }

  /**
   * フォームで選択された画像を一時格納する
   * @param evarg イベント引数
   */
  public saveImage(evarg: any): void {
    // 入力ファイルチェック
    const files = evarg.target.files as any[];
    if (!files || files.length <= 0) {
      return;
    }
    // 画像読み込み
    const reader = new FileReader();
    reader.onload = () => {
      this.tempImageSrc = reader.result;
    };
    reader.readAsDataURL(files[0]);
    this.data.technologyImage = files[0];
  }

  /**
   * 選択された画像が適切なものかチェックを行った後、画面に表示する
   * @param tempImageEl 画像読み込み用イメージ
   */
  public loadedTempImage(tempImageEl: HTMLImageElement): void {
    // 画像サイズをチェック
    if (tempImageEl.height >= 512 || tempImageEl.width >= 512) {
      this.snackbar.error('画像サイズが大きすぎます。');
      return;
    }
    // 画像が正方形であるかチェック
    if (tempImageEl.height !== tempImageEl.width) {
      this.snackbar.error('正方形の画像を選択してください。');
      return;
    }
    this.iconPath = this.tempImageSrc;
  }

  /**
   * クリックを発生させる
   * @param el 選択した画像情報
   */
  public onClickFileInputButton(el: HTMLInputElement): void {
    el.click();
  }

  /**
   * 技術を登録する
   */
  public postTechnology(): void {
    if (!this.data.name) {
      this.snackbar.error('技術名を入力してください。');
      return;
    }

    this.data.createBy = this.appAuthService.userId;
    this.data.updateBy = this.appAuthService.userId;

    this.technologyService.postTechnology(this.data).subscribe(
      next => {
        this.snackbar.success('技術を追加しました。');
        this.dialogRef.close(this.data);
      },
      err => {
        this.snackbar.error('技術追加に失敗しました。');
      }
    );
  }

  /**
   * DBに登録済みの類似技術名を検索する
   */
  public changeInputTechnologyName(): void {
    this.registrationTechList = [];

    const lowerName = this.data.name?.trim().toLowerCase();
    if (!lowerName) {
      return;
    }

    // 類似技術取得
    this.registrationTechList = this.techList.filter(tech => {
      return tech.keyName.trim().toLowerCase().includes(lowerName);
    });
  }
}
