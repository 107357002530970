import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(Date.now(), 'MSAL loggerCallback', logLevel, message);
}

const baseUrl = 'https://front.dev.basic-learning.net';

export const environment = {
  production: false,

  msalConfig: {
    auth: {
      clientId: 'fbbd8f2b-84e1-41c8-83f8-1250a3e9a5cf',
      authority:
        'https://login.microsoftonline.com/492503ad-0b66-4c46-bb3d-034ded71acba',
      redirectUri: baseUrl
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: false // set to true for IE 11
    }
  },
  baseUrl: baseUrl,

  apiUrl:
    'https://basic-learning-back-endpoints-dev-fjnd2vezya-an.a.run.app/api'
};
