import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSnackbarService } from 'src/app/service/app-snackbar/app-snackbar.service';
import {
  TechnologyViewModel,
  TechnologyDetailInit,
  Note,
  Tag,
  TechnologyNoteQuery,
  DocumentPagingModel
} from '../../technology.models';
import { TechnologyService } from 'src/app/service/technology/technology.service';
import { NoteService } from 'src/app/service/note/note.service';
import { UserService } from 'src/app/service/user/user.service';
import { RouterService } from 'src/app/service/router/router.service';

@Component({
  selector: 'app-technology-note',
  templateUrl: './technology-note-home.component.html',
  styleUrls: ['./technology-note-home.component.scss']
})
export class TechnologyNoteHomeComponent implements OnInit {
  public currentTech = new TechnologyViewModel();
  private techId = '';

  public notes: Note[] = [];

  /** 記事取得判別用 */
  public isLast = false;

  /** 次の記事ID */
  public nextDocId: string;

  /** 取得する記事数 */
  private readonly QUERYTAKE: number = 10;

  public tagName: string;
  /** ジャンルタグ */
  public defaultTags: Tag[];

  /** 表示中ジャンルタグ */
  public currentTag: Tag;

  constructor(
    private technologyService: TechnologyService,
    private noteService: NoteService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private routerService: RouterService,
    private router: Router,
    private snackbar: AppSnackbarService
  ) {}

  ngOnInit(): void {
    this.techId = this.activatedRoute.snapshot.paramMap.get('techId') ?? '';
    this.tagName = this.activatedRoute.snapshot.paramMap.get('tagName') ?? '';

    // 初期表示情報の取得
    this.technologyService
      .getTechnology<TechnologyDetailInit>(this.techId)
      .subscribe(data => {
        if (!data || !data.technology) {
          this.snackbar.error('対象の技術が存在しません');
          this.currentTech = new TechnologyViewModel();
          return;
        }

        // 表示する技術を格納
        this.currentTech = data.technology;

        // 初期表示記事の取得
        this.getNotes('');
      });
    // 初期表示タグの取得
    this.userService.getDefaultTag().subscribe(tags => {
      this.defaultTags = tags;
      // 選択中のジャンルタグの表示名を取得
      this.currentTag = this.defaultTags.find(
        tag => tag.keyName === this.tagName
      );
    });
  }

  /**
   * 記事の取得
   * @param skip スキップ件数
   */
  public getNotes(docId: string): void {
    let query = new TechnologyNoteQuery();
    // ページング用クエリ情報格納
    query.keyName = this.currentTech.keyName;
    query.tag = this.tagName;
    query.take = this.QUERYTAKE;
    query.docId = docId;

    this.noteService.getNotes<DocumentPagingModel>(query).subscribe(data => {
      if (data.nextDocId === null) {
        this.isLast = true;
      }

      // 次の記事があるか判定
      if (!this.isLast) {
        this.nextDocId = data.nextDocId;
      }

      // 表示する記事を格納
      data.notes.forEach(note => {
        this.notes.push(note);
      });
    });
  }

  /**
   *  メニューで記事種別を押下時の画面描画切り替え
   * @param noteType 記事種別の文字列
   */
  public clickedReadMoreMenu(noteType: string): void {
    const techId = this.activatedRoute.snapshot.paramMap.get('techId') ?? '';
    window.location.replace(`technologies/${techId}/note/${noteType}`);
  }

  /** メニューで「全体」を押下時の画面遷移 */
  public clickedTechnologyDetail(): void {
    const techId = this.activatedRoute.snapshot.paramMap.get('techId') ?? '';
    this.router.navigateByUrl(`technologies/${techId}`);
  }

  /** ジャンルタグ選択時 */
  public clickGenre(tagName: string): void {
    this.routerService.navigateOnPage(
      `technologies/${this.techId}/note/${tagName}`
    );
  }

  /** もっと見る押下時 */
  public clickedReadMore(): void {
    this.getNotes(this.nextDocId);
  }
}
