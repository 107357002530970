/** 記事情報 */
export class Note {
  /** タグコード */
  public tags: string[] = [];
  /** 記事種別コード */
  public genres: GenreMap = {};
  /** タイトル */
  public title: string = '';
  /** 記事URL */
  public url: string = '';
  /** 評価 */
  public rating: number | null = null;
  /** 著者ID */
  public writtenBy: string = '';
  /** 著者名 */
  public writtenByName: string = '';
  /** 作成者ID(登録者ID) */
  public createBy: string = '';
  /** 作成者名(登録者名) */
  public createByName: string = '';
  /** 更新日 */
  public updateAt?: Date;
  /** 更新者ID(登録者ID) */
  public updateBy: string = '';
}

export type GenreMap = { [key: string]: boolean };
