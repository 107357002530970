<div class="basic-container">
  <p>sandbox works!</p>
  <button mat-flat-button color="primary" (click)="clickedTest()">
    プライマリーボタン
  </button>
  <button mat-flat-button color="accent">アクセント</button>
  <button mat-flat-button color="warn">警告</button>

  <button mat-flat-button color="primary" (click)="showSnackbar()">
    snackbarテスト
  </button>
  <div class="block-padding background-light">
    ライト
    <div class="block-padding background-middle">
      ミドル
      <div class="block-padding background-dark">ダーク</div>
    </div>
  </div>
</div>
