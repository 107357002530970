import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  InteractionStatus,
  RedirectRequest
} from '@azure/msal-browser';
import { concat, interval, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppAuthService } from './service/app-auth/app-auth.service';
import { AppSnackbarService } from './service/app-snackbar/app-snackbar.service';
import { UserService } from './service/user/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public title = 'basic-learning-front';
  public isIframe = false;
  public logined = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private appAuthService: AppAuthService,
    private userService: UserService,
    private authService: MsalService,
    private snackbar: AppSnackbarService,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  ngOnInit(): void {
    this.authService.initialize().subscribe(_ => {
      this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
      this.msalBroadcastService.inProgress$
        .pipe(
          // filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe(x => {
          // canActive判定ごとに呼ばれる
          // console.log(Date.now(), 'msalBroadcastService.inProgress$', x, window.location);
          if (x !== InteractionStatus.None) {
            return;
          }

          // 有効なアカウントを設定する (ないならログインだ！)
          const account = this.SetActiveAccount();
          if (!account) {
            console.log(Date.now(), 'login redirect...', window.location.href);
            this.authService.loginRedirect({
              scopes: ['email'],
              redirectStartPage: window.location.href
            });
            return;
          }

          // トークンの更新を行う
          this.authService
            .acquireTokenSilent({
              scopes: ['email'],
              account: account
            })
            .subscribe(
              ok => {
                // トークン取得成功
                if (!this.logined) {
                  this.finishedLogin();
                }
                this.logined = true;

                console.log(
                  Date.now(),
                  'inprocess acquireTokenSilent logined.',
                  ok
                );
              },
              ng => {
                // キャッシュされたトークが無効だった場合
                console.log('acquireTokenSilent ng', ng, window.location.href);
                this.authService
                  .acquireTokenRedirect({
                    scopes: ['email'],
                    account: account,
                    redirectStartPage: window.location.href
                  })
                  .subscribe();
              }
            );
        });

      // ハンドル登録
      this.authService.instance
        .handleRedirectPromise()
        .then(data => {
          if (data) {
            // ログイン完了
            if (this.logined) {
              return;
            }

            this.logined = true;
            this.authService.instance.setActiveAccount(data.account);
            this.finishedLogin();
            console.log(Date.now(), 'handle logined.', data);
          }
        })
        .catch(ct => {
          console.error(Date.now(), 'handle login catch exception', ct);
        });
    });
  }

  private finishedLogin() {
    console.log(Date.now(), 'finishedLogin', window.location.href);
    // ユーザーの確認登録を行う
    if (this.isIframe) {
      return;
    }

    if (window.location.href.includes('#') === true) {
      console.log(Date.now(), 'reload');
      this.router
        .navigateByUrl('/sandbox', { skipLocationChange: true })
        .then(() => {
          this.router.navigateByUrl('');
        });
    }

    this.userService
      .init({
        userId: this.appAuthService.userId,
        userName: this.appAuthService.userName
      })
      .subscribe();
  }

  /**
   * アクティブアカウントを設定する
   * @returns 現在有効なアクティブアカウント
   */
  private SetActiveAccount(): AccountInfo | null {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount) {
      return activeAccount;
    }

    // 有効なアカウント一覧を取得する
    const accounts = this.authService.instance.getAllAccounts();
    if (!accounts || accounts.length <= 0) {
      return null;
    }

    // 有効なアカウントを1つに決める
    let account = accounts.find(
      x => (x.idTokenClaims as any).aud === environment.msalConfig.auth.clientId
    );
    if (!account) {
      account = accounts[0];
    }
    this.authService.instance.setActiveAccount(account);

    return account;
  }
}
