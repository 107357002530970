import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterService {
  public get url(): string {
    return this.router.url;
  }

  constructor(private router: Router) {}

  public navigateByUrl(url: string) {
    this.router.navigateByUrl(url);
  }

  public navigateOnPage(uri: string) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
