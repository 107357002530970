import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bookmarklet-dialog',
  templateUrl: './bookmarklet-dialog.component.html',
  styleUrls: ['./bookmarklet-dialog.component.scss']
})
export class BookmarkletDialogComponent implements OnInit {
  /** 画面遷移ブックマークレット */
  public bookmarkletScript: string =
    "javascript: (function(){title=document.title,href=document.location.href,encodeUrl=encodeURIComponent(href),encodeTitle=encodeURIComponent(title).replace('(','%2528').replace(')','%2529'),url='" +
    environment.baseUrl +
    '/technology/noteAdd/' +
    "'+encodeTitle+'/'+encodeUrl,window.open(url,'_blank')})();";

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  public getSantizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
